<template>
<h1 class="text-center">MotionPathPlugin</h1>
</template>

<script>
export default {
  name: "MotionPathPlugin"
}
</script>

<style scoped>

</style>